import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'



export default () => (
    <div id="app" className="contactus">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Contact Page"></meta>
          <title>WRAL Digital Solutions | Contact Us</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/confirmation" />
        </Helmet>
      <PreHeader/>
      <Header/>

      <div className="contact confirmation">
      <h1> Thank You For Your Interest!</h1>
      <h4>We will be in touch with you shortly!</h4>
      </div>
      <div id="contact">
        <Footer/>
      </div>
    </div>
  );
